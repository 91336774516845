<template>
  <div>
    <v-col cols="12">
      <v-row>
        <v-tabs color="white" v-model="tab" fixed-tabs background-color="orange" dark>
          <v-tab :key="1"> List Penagihan </v-tab>
          <v-tab :key="2"> Pending ({{ this.countFormPenagihan }}) </v-tab>
        </v-tabs>
      </v-row>
    </v-col>

    <v-tabs-items v-model="tab" touchless>
      <!-- TAB 1 -->
      <v-tab-item :key="1">
        <div v-if="listPenagihan.length > 0">
          <v-card class="pa-5 rounded-lg text-center text-caption" v-if="loading">
            <span>Sedang Memuat Data....</span>
          </v-card>
          <v-card v-else>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Silahkan Ketik Untuk Mencari..." single-line hide-details></v-text-field>
            </v-card-title>
            <div class="table-container pa-4">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                class="small-text"
                mobile-breakpoint="220"
                :headers="headers"
                :items="listPenagihan"
                :search="search"
                item-key="name"
                :footer-props="{
                  'items-per-page-options': [5, 10, 25],
                  'show-current-page': true,
                }"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index" @click="showDetail(item.id, item.account_financing_no)">
                      <td>{{ item.fa_name }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.created_date }}</td>
                      <td class="text-right">{{ formatRupiah(item.amount) }}</td>
                    </tr>
                    <tr v-if="items.length">
                      <td colspan="3" class="text-right font-weight-bold" style="font-size: 11px">TOTAL TERTAGIH</td>
                      <td class="text-right font-weight-bold" style="font-size: 11px">
                        {{ formatRupiah(getTotalTertagih(items)) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </div>
        <div v-else>
          <v-card class="pa-5 rounded-lg text-center text-caption">
            <span> Belum ada data yang bisa ditampilkan.. </span>
          </v-card>
        </div>
      </v-tab-item>

      <!-- TAB 2 -->
      <v-tab-item :key="2">
        <v-card class="pa-5 text-center" v-if="listPending.length > 0">
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Silahkan Ketik Untuk Mencari..." single-line hide-details></v-text-field>
          </v-card-title>
          <div class="table-container pa-4">
            <v-data-table
              class="small-text"
              mobile-breakpoint="220"
              :headers="headersPending"
              :items="listPending"
              :search="search"
              item-key="name"
              :footer-props="{
                'items-per-page-options': [5, 10, 25],
                'show-current-page': true,
              }"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index" @click="showDetail(item.id, item.account_financing_no)">
                    <td class="text-left">{{ item.name }}</td>
                    <td class="text-right">{{ formatRupiah(item.amount) }}</td>
                  </tr>
                  <tr v-if="items.length">
                    <td class="text-left font-weight-bold" style="font-size: 11px">TOTAL TERTAGIH</td>
                    <td class="text-right font-weight-bold" style="font-size: 11px">
                      {{ formatRupiah(getTotalTertagih(items)) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
        </v-card>
        <div v-else>
          <v-card class="pa-5 rounded-lg text-center text-caption">
            <span> Belum ada data yang bisa ditampilkan.. </span>
          </v-card>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <!-- Dialog Detail -->
    <v-dialog v-model="dialog" max-width="480px">
      <div @click="dialog = false">
        <!-- Loading Indicator -->
        <v-card v-if="isLoading" class="pa-5 rounded-lg text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <v-card-text>Loading data...</v-card-text>
        </v-card>

        <!-- Data ditemukan -->
        <v-card
          v-else-if="detail && Object.keys(detail).length > 0 && isOnline"
          class="pa-5 rounded-lg"
          :style="{
            backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg-lhp)' : 'var(--light-bg-lhp)',
          }"
          style="z-index: 1; backdrop-filter: blur(5px); -webkit-backdrop-filter: blur(5px)"
        >
          <div>
            <v-img :src="detail.photo.startsWith('data:image') ? detail.photo : 'data:image/jpeg;base64,' + detail.photo" contain max-height="300" class="rounded-lg"></v-img>
          </div>

          <!-- Tanggal -->
          <div class="d-flex justify-end mt-5">
            <span class="text-caption font-weight-bold">{{ formatDate(detail.transaction_date) }}</span>
          </div>

          <!-- Status -->
          <v-sheet class="px-5 py-3 rounded-xl my-3 d-flex align-center" style="background-color: rgba(183, 239, 197, 0.5); color: rgb(26, 116, 49)">
            <v-icon color="rgb(26, 116, 49)" class="mr-2 text-subtitle-1">mdi-emoticon-outline</v-icon>
            <span class="text-caption font-weight-bold">Tertagih</span>
          </v-sheet>

          <!-- Informasi Penagihan -->
          <v-card flat class="pa-5 rounded-lg">
            <v-row class="py-1">
              <v-col cols="6" class="text-caption text--secondary">KC</v-col>
              <v-col cols="6" class="text-caption text--secondary text-right">{{ detail.branch_name }}</v-col>
            </v-row>
            <v-divider />

            <v-row class="py-1">
              <v-col cols="6" class="text-caption text--secondary">ID Anggota</v-col>
              <v-col cols="6" class="text-caption text--secondary text-right">{{ detail.cif_no }}</v-col>
            </v-row>
            <v-divider />

            <v-row class="py-1">
              <v-col cols="6" class="text-caption text--secondary">Nama Anggota</v-col>
              <v-col cols="6" class="text-caption text--secondary text-right">{{ detail.name }}</v-col>
            </v-row>
            <v-divider />

            <v-row class="py-1">
              <v-col cols="6" class="text-caption text--secondary">Nama Petugas</v-col>
              <v-col cols="6" class="text-caption text--secondary text-right">{{ detail.fa_name }}</v-col>
            </v-row>
            <v-divider />

            <v-row class="py-1">
              <v-col cols="6" class="text-caption text--secondary">Keterangan</v-col>
              <v-col cols="6" class="text-caption text--secondary text-right">{{ detail.description }}</v-col>
            </v-row>
          </v-card>
        </v-card>

        <!-- Data tidak ditemukan -->
        <v-card v-else-if="!isOnline" class="pa-5 rounded-lg text-center">
          <v-card-title class="text-subtitle-1 font-weight-bold"> Tidak Tersedia Dalam Versi Offline Mode </v-card-title>
          <v-card-text> Mohon cek kembali jaringan internet anda. </v-card-text>
        </v-card>
        <v-card v-else class="pa-5 rounded-lg text-center">
          <v-card-title class="text-subtitle-1 font-weight-bold"> Data tidak ditemukan </v-card-title>
          <v-card-text> Mohon cek kembali atau coba beberapa saat lagi. </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import services from "@/services";
import { deleteData, getAllData, getData, saveData } from "@/utils/indexedDB";
export default {
  data() {
    return {
      tab: null,
      dialog: false,
      detail: [],
      loading: false,
      isLoading: false,
      search: "",
      visibleItems: [],
      headers: [
        { text: "NAMA PETUGAS", align: "start", sortable: true, value: "fa_name" },
        { text: "NAMA ANGGOTA", value: "name" },
        { text: "TANGGAL", value: "created_date" },
        { text: "TERTAGIH", value: "amount", align: "end" },
      ],
      headersPending: [
        { text: "NAMA ANGGOTA", value: "name" },
        { text: "TERTAGIH", value: "amount", align: "end" },
      ],
      listPenagihan: [],
      alert: {
        show: false,
        msg: "",
      },
      isOnline: navigator.onLine,
      countFormPenagihan: 0,
      listPending: [],
    };
  },
  computed: {
    ...mapGetters(["user", "userRole"]),
  },
  methods: {
    async showDetail(id, account_financing_no) {
      try {
        if (!id) {
          console.warn("Id tidak valid!");
          return;
        }
        if (!account_financing_no) {
          console.warn("Account Financing tidak valid!");
          return;
        }

        this.isLoading = true;
        this.dialog = true;

        let payload = new FormData();
        payload.append("id", id);
        payload.append("account_financing_no", account_financing_no);

        if (!this.user?.token) {
          console.error("Token pengguna tidak ditemukan!");
          return;
        }

        const req = await services.detailPenagihan(payload, this.user.token);
        const response = req.data;

        if (response && response.status && response.data) {
          this.detail = response.data;
        } else {
          console.warn("Status false atau response tidak sesuai!");
          this.detail = {};
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);
        this.$store.dispatch("showAlert", {
          show: true,
          msg: error.message || "Terjadi kesalahan",
          color: "error",
          position: "top",
        });
      } finally {
        this.isLoading = false; // Matikan loading setelah data didapat
      }
    },

    formatDate(date) {
      if (!date) return "Tanggal tidak tersedia";
      const options = { weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" };
      return new Date(date).toLocaleDateString("id-ID", options);
    },
    formatRupiah(value) {
      return new Intl.NumberFormat("id-ID").format(value);
    },
    getTotalTertagih(items) {
      return items?.reduce((total, item) => total + Number(item.amount), 0) || 0;
    },
    async getListPending() {
      const form_penagihan = await getAllData("form_penagihan");
      this.countFormPenagihan = form_penagihan.length;
      form_penagihan.forEach((item) => {
        this.listPending.push(item.entry.data);
      });
    },
    async getListPenagihan() {
      if (this.isOnline) {
        this.loading = true;
        let payload = new FormData();
        payload.append("branch_code", this.user.branch_code);
        try {
          const req = await services.listPenagihan(payload, this.user.token);
          const response = req.data;
          if (response.status === true && response.data !== null) {
            if (this.userRole.role_id === 1) {
              this.listPenagihan = response.data.filter((item) => item.fa_code === this.user.fa_code);
            } else {
              this.listPenagihan = response.data;
            }
            await deleteData("list_penagihan", "list_penagihan");
            await saveData("list_penagihan", this.listPenagihan, "list_penagihan");
          }
        } catch (error) {
          this.$store.dispatch("showAlert", { show: false });
          setTimeout(() => {
            this.$store.dispatch("showAlert", {
              show: true,
              msg: error,
              color: "error",
              position: "top",
            });
          }, 3000);
        } finally {
          this.loading = false;
        }
      } else {
        console.log("offline list penagihan");
        console.log(this.user.fa_code);
        this.loading = true;
        const list_penagihan = await getData("list_penagihan", "list_penagihan");
        console.log(list_penagihan);
        this.listPenagihan = list_penagihan?.data || [];
        if (this.userRole.role_id === 1) {
          this.listPenagihan = list_penagihan.data.filter((item) => item.fa_code === this.user.fa_code);
          this.listPenagihan = list_penagihan.data;
        } else {
          this.listPenagihan = list_penagihan.data;
        }
        this.loading = false;
      }
    },
    async syncOfflineTransactions() {
      try {
        const offlineTransactions = (await getAllData("form_penagihan")) || [];
        console.log("Data Offline yang ditemukan:", offlineTransactions);

        if (offlineTransactions.length === 0) {
          console.log("Tidak ada transaksi offline untuk disinkronkan.");
          return;
        }

        let total = offlineTransactions.length;
        let sukses = 0;

        for (const transaction of offlineTransactions) {
          try {
            if (!this.user?.token) {
              console.error("Token pengguna tidak ditemukan! Transaksi tidak bisa dikirim.");
              return;
            }

            const payload = new FormData();
            for (const key in transaction.entry.data) {
              payload.append(key, transaction.entry.data[key]);
            }

            console.log("Mengirim transaksi:", transaction.id, payload);

            const req = await services.transactionPenagihan(payload, this.user.token);
            const response = req.data;

            console.log("Response dari API:", response);

            if (response.status === true) {
              console.log(`Berhasil mengirim (${sukses + 1}/${total}):`, transaction.id);
              await deleteData("form_penagihan", transaction.id);
              sukses++;
            } else {
              console.error("Gagal mengirim transaksi:", response.msg || "Response status false");
            }
          } catch (error) {
            console.error("Gagal mengirim transaksi offline:", error.response ? error.response.data : error);
          }
        }

        if (sukses > 0) {
          this.$store.dispatch("showAlert", { show: false });
          setTimeout(() => {
            this.$store.dispatch("showAlert", {
              show: true,
              msg: `Semua transaksi offline berhasil dikirim! (${sukses}/${total})`,
              color: "success",
              position: "top",
            });
            this.getListPenagihan();
            this.getListPending();
          }, 3000);
        } else {
          this.$store.dispatch("showAlert", {
            show: true,
            msg: "Tidak ada transaksi yang berhasil dikirim. Periksa koneksi atau data.",
            color: "error",
            position: "top",
          });
        }
      } catch (error) {
        console.error("Kesalahan saat memproses sinkronisasi offline:", error);
      }
    },
    updateNetworkStatus() {
      this.isOnline = navigator.onLine;
    },
  },
  mounted() {
    this.getListPenagihan();
    this.getListPending();
    this.onlineHandler = async () => {
      this.updateNetworkStatus();
      await this.syncOfflineTransactions();
    };

    window.addEventListener("online", this.onlineHandler);
    window.addEventListener("offline", this.updateNetworkStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.onlineHandler);
    window.removeEventListener("offline", this.updateNetworkStatus);
  },
};
</script>
<style scoped>
.overflow-x-auto {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
::v-deep(.v-data-table) {
  font-size: 11px !important;
}

::v-deep(.v-data-table th),
::v-deep(.v-data-table td) {
  font-size: 11px !important;
}
::v-deep(.v-data-footer) {
  font-size: 8px !important;
}
::v-deep(.v-data-footer__select .v-select__selections .v-select__selection--comma) {
  font-size: 8px !important;
}
::v-deep(.v-input) {
  font-size: 12px !important;
}
::v-deep(.v-label) {
  font-size: 12px !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
