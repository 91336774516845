<template>
  <div class="px-5">
    <Toast :show="alert.show" :msg="alert.msg" :color="alert.color" position="top" />
    <div class="align-center">
      <v-row class="mt-1">
        <v-col cols="12">
          <div>
            <div class="d-flex justify-space-between mt-2">
              <div class="mb-5">
                <router-link to="/penagihan/dashboard">
                  <v-btn class="rounded-lg purple lighten-1 white--text">
                    <v-icon left class="p-4">mdi-arrow-left-circle</v-icon>
                    Kembali
                  </v-btn>
                </router-link>
              </div>
              <div class="mb-5">
                <v-btn class="rounded-lg" @click="sheet = true">
                  <v-icon left> mdi-plus-circle </v-icon>
                  Tambah
                </v-btn>
              </div>
            </div>
            <v-row class="mb-5 pb-5">
              <v-col col="12">
                <ListPenagihan />
              </v-col>
            </v-row>

            <!-- Bottom Sheet -->
            <v-bottom-sheet v-model="sheet" max-width="480px" inset persistent>
              <v-sheet class="pa-1 elevation-0">
                <!-- Header -->
                <div class="sheet-header text-center">
                  <v-btn class="ma-2" outlined x-small fab color="grey" @click="(sheet = false), clearAll()">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                  <h3 class="form-title mt-4">Form Penagihan</h3>
                </div>
                <!-- End Header -->

                <v-divider class="mt-2" />

                <!-- Isi konten -->
                <div class="sheet-content">
                  <v-form @submit.prevent="doSubmit">
                    <!-- Status Anggota -->
                    <v-row class="py-1 align-center">
                      <v-col cols="6" class="text-left">
                        <div>
                          <span class="text-subtitle-1 font-weight-bold">Anggota Membayar</span>
                        </div>
                        <div style="margin-top: -8px">
                          <span class="text--secondary text-caption">Status</span>
                        </div>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end">
                        <v-switch v-model="isSwitched" insert label="Bayar"></v-switch>
                      </v-col>
                    </v-row>
                    <v-divider />

                    <!-- Pilih Anggota -->
                    <v-row class="mt-2 align-center">
                      <v-col cols="12">
                        <v-autocomplete dense autocomplete="off" outlined v-model="selectedAnggota" :items="opt.anggota" item-value="data" item-text="text" label="Pilih Anggota" @change="tampilkanDetail" />
                      </v-col>
                    </v-row>
                    <v-divider />

                    <!-- Pokok -->
                    <div v-if="form.data.is_pay === 1">
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field class="text-right" type="text" dense outlined :value="formatRupiah(selectedAnggota?.pokok || pokok)" label="Pokok" readonly />
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>

                    <!-- Margin -->
                    <div v-if="form.data.is_pay === 1">
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field class="text-right" type="text" dense outlined :value="formatRupiah(selectedAnggota?.pokok || margin)" label="Margin" readonly />
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>

                    <!-- Nominal Seharusnya -->
                    <div v-if="form.data.is_pay === 1">
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field class="text-right" type="text" dense outlined :value="formatRupiah(selectedAnggota?.angsuran || nominal_seharusnya)" label="Angsuran" readonly />
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>

                    <!-- Nominal -->
                    <div v-if="form.data.is_pay === 1">
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field class="text-right" type="text" dense outlined v-model="form.data.nominal" label="Bayar Angsuran" v-mask="formatRibuan" required :rules="[(v) => !!v || 'BayarAngsuran is required']" />
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>

                    <!-- Keterangan -->
                    <div v-if="form.data.is_pay === 0">
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-textarea dense autocomplete="off" rows="3" outlined v-model="form.data.keterangan" label="Keterangan" required :rules="[(v) => !!v || 'Keterangan is required']" />
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>

                    <!-- Kamera -->
                    <v-row>
                      <v-col cols="12">
                        <div class="pt-5 text-center">{{ form.data.latitude }} | {{ form.data.longitude }}</div>
                        <div class="web-camera-container">
                          <div class="camera-button">
                            <v-btn small @click="toggleCamera">
                              <span v-if="!isCameraOpen"> <v-icon left>mdi-camera</v-icon> Open Camera</span>
                              <span v-else> <v-icon left>mdi-camera-off</v-icon> Close Camera </span>
                            </v-btn>
                          </div>
                          <div v-show="isCameraOpen && isLoading" class="camera-loading">
                            <ul class="loader-circle">
                              <li></li>
                              <li></li>
                              <li></li>
                            </ul>
                          </div>
                          <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ flash: isShotPhoto }">
                            <video v-show="!isPhotoTaken" ref="camera" :class="isFrontCamera ? 'front-camera' : 'back-camera'" :facingMode="isFrontCamera ? 'user' : 'environment'" autoplay></video>
                            <canvas :class="isFrontCamera ? 'front-camera' : 'back-camera'" :facingMode="isFrontCamera ? 'user' : 'environment'" v-show="isPhotoTaken" id="photoTaken" ref="canvas"></canvas>
                            <div class="camera-shoot d-flex justify-center align-center" style="width: 100%; position: relative">
                              <!-- Switch Camera (di kiri) -->
                              <v-btn @click="switchCamera" fab small style="position: absolute; left: 0">
                                <v-icon color="white">mdi-camera-flip</v-icon>
                              </v-btn>

                              <!-- Take Photo (di tengah) -->
                              <v-btn @click="takePhoto" fab large>
                                <v-icon>{{ form.data.selfi != null ? "mdi-refresh-circle" : "mdi-camera-iris" }}</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                    <v-divider />
                    <!-- Tombol Aksi -->
                    <v-row>
                      <v-col cols="12" class="d-flex" style="padding: 20px 0px !important">
                        <v-col cols="6">
                          <v-btn outlined color="orange" depressed @click="(sheet = false), clearAll()" :disabled="form.loading" style="width: 100%"> Cancel </v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn depressed :loading="loadingBtn" type="submit" :disabled="(loadingBtn, isFormIncomplete)" color="success" @click="loader = 'loadingBtn'" style="width: 100%">
                            Kirim
                            <template v-slot:loader>
                              <span>Sedang Mengirim...</span>
                            </template>
                          </v-btn>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-form>
                </div>
              </v-sheet>
            </v-bottom-sheet>
            <!-- End Bottom Sheet -->
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/components/Toast";
import services from "@/services";
import ListPenagihan from "./ListPenagihan.vue";
import helper from "@/utils/helper";
import { getData, getAllData, addDataWithoutEncryption, deleteData, decryptData } from "@/utils/indexedDB";
export default {
  name: "FormPenagihan",
  components: {
    Toast,
    ListPenagihan,
  },
  data() {
    return {
      isFrontCamera: true,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      loader: null,
      loadingBtn: false,
      form: {
        data: {
          name: "",
          account_financing_no: null,
          selfi: null,
          nominal: 0,
          keterangan: "",
          is_pay: 0,
          latitude: 0,
          longitude: 0,
        },
      },
      opt: {
        anggota: [],
      },
      pokok: 0,
      margin: 0,
      nominal_seharusnya: 0,
      selectedAnggota: null,
      anggotaDetail: null,
      alert: {
        show: false,
        msg: "",
        color: "primary",
      },
      isSwitched: false,
      sheet: false,
      startY: 0,
      isDragging: false,
      isOnline: navigator.onLine,
    };
  },

  mounted() {
    this.getInfoTertunggak();
    this.onlineHandler = async () => {
      this.updateNetworkStatus();
    };

    window.addEventListener("online", this.onlineHandler);
    window.addEventListener("offline", this.updateNetworkStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.onlineHandler);
    window.removeEventListener("offline", this.updateNetworkStatus);
  },
  methods: {
    ...helper,

    async geoLocation() {
      if (!navigator.geolocation) {
        this.$store.dispatch("showAlert", { show: false });
        setTimeout(() => {
          this.$store.dispatch("showAlert", {
            show: true,
            msg: "⚠️" + " " + "Maaf, Browser Anda Tidak Mendukung",
            color: "warning",
            position: "top",
          });
        }, 3000);
        return;
      }

      const updateLocation = (position) => {
        this.form.data.latitude = position.coords.latitude;
        this.form.data.longitude = position.coords.longitude;

        // Simpan lokasi ke localStorage sebagai fallback
        localStorage.setItem(
          "lastLocation",
          JSON.stringify({
            latitude: this.form.data.latitude,
            longitude: this.form.data.longitude,
          })
        );
      };

      const getLastLocation = () => {
        const lastLocation = localStorage.getItem("lastLocation");
        if (lastLocation) {
          const { latitude, longitude } = JSON.parse(lastLocation);
          this.form.data.latitude = latitude;
          this.form.data.longitude = longitude;
        }
      };

      const handleGeoError = (error) => {
        let msg = "Gagal mendapatkan lokasi.";
        switch (error.code) {
          case error.PERMISSION_DENIED:
            msg = "Anda menolak akses lokasi. Silakan aktifkan izin lokasi di pengaturan.";
            getLastLocation();
            if (this.watchId) {
              navigator.geolocation.clearWatch(this.watchId);
            }
            break;

          case error.POSITION_UNAVAILABLE:
            msg = "Lokasi tidak tersedia. Pastikan GPS Anda aktif.";
            getLastLocation();
            break;

          case error.TIMEOUT:
            msg = "Pengambilan lokasi terlalu lama. Menggunakan lokasi terakhir yang tersedia.";
            getLastLocation();
            break;
        }

        this.$store.dispatch("showAlert", { show: false });
        setTimeout(() => {
          this.$store.dispatch("showAlert", {
            show: true,
            msg: "⚠️" + " " + msg,
            color: "warning",
            position: "top",
          });
        }, 3000);
      };

      if (!navigator.onLine) {
        getLastLocation();
        return;
      }

      navigator.geolocation.getCurrentPosition(updateLocation, handleGeoError, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      });

      if (!this.watchId) {
        this.watchId = navigator.geolocation.watchPosition(updateLocation, handleGeoError, {
          enableHighAccuracy: true,
        });
      }
    },
    updateNetworkStatus() {
      this.isOnline = navigator.onLine;
    },

    async getInfoTertunggak() {
      let payload = new FormData();
      payload.append("branch_code", this.user.branch_code);
      try {
        const info_tertunggak = await getData("info_tertunggak", "info_tertunggak");
        if (info_tertunggak) {
          const req = info_tertunggak?.data?.data;
          if (req?.status === true) {
            this.opt.anggota = req?.data.map((item) => ({
              value: item.acoount_financing_no,
              text: item.nama + " - " + item.cm_name + " - " + item.nick_name,
              data: item,
            }));
          } else {
            this.$store.dispatch("showAlert", { show: false });
            setTimeout(() => {
              this.$store.dispatch("showAlert", {
                show: true,
                msg: "⚠️" + " " + req.msg || "Terjadi Kesalahan",
                color: "error",
                position: "top",
              });
            }, 3000);
          }
        } else {
          this.$store.dispatch("showAlert", { show: false });
          setTimeout(() => {
            this.$store.dispatch("showAlert", {
              show: true,
              msg: `ℹ️ Data tidak tersedia, Silahkan Unduh terlebih dahulu`,
              color: "info",
              position: "top",
            });
          }, 3000);
        }
      } catch (error) {
        this.$store.dispatch("showAlert", { show: false });
        setTimeout(() => {
          this.$store.dispatch("showAlert", {
            show: true,
            msg: "⚠️" + " " + error,
            color: "error",
            position: "top",
          });
        }, 3000);
      }
    },

    tampilkanDetail() {
      this.form.data.account_financing_no = this.selectedAnggota.account_financing_no;
      this.form.data.name = this.selectedAnggota.nama;
    },

    // CAMER SELFIR
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        // Menampilkan notifikasi jika terjadi error
        audio: false,
        video: {
          facingMode: this.isFrontCamera ? "user" : "environment", // Menggunakan "user" untuk kamera depan, "environment" untuk kamera belakang
        },
      });

      // Menutup overlay

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    stopCameraStream() {
      if (this.$refs.camera && this.$refs.camera.srcObject) {
        this.$refs.camera.srcObject.getTracks().forEach((track) => track.stop());
        this.$refs.camera.srcObject = null;
      }
    },

    previewImage(event) {
      let theImg = null;
      let vm = this;
      const foto = this.$refs["fm-foto"];
      let reader = new FileReader();
      theImg = event.target.files[0];
      reader.readAsDataURL(theImg);
      reader.onload = function () {
        vm.form.data.selfi = reader.result;
        foto.type = "text";
        foto.type = "file";
      };
      reader.onerror = function () {
        vm.form.data.selfi = null;
        foto.type = "text";
        foto.type = "file";
      };
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;
        setTimeout(() => {
          this.isShotPhoto = false;
        }, 50);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      if (this.isPhotoTaken) {
        const canvas = this.$refs.canvas;
        const video = this.$refs.camera;
        const context = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        this.form.data.selfi = canvas.toDataURL();
      } else {
        this.form.data.selfi = null;
      }
    },

    switchCamera() {
      this.isFrontCamera = !this.isFrontCamera;
      this.form.data.selfi = null;
      this.isPhotoTaken = false;
      this.stopCameraStream();
      this.createCameraElement();
    },
    // END CAMERA SELFIE

    // FORM SUBMIT

    async doSubmit() {
      this.loadingBtn = true;
      try {
        let payload = new FormData();
        const cleanAmount = this.form.data.nominal.toString().replace(/\D/g, "");
        payload.append("name", this.form.data.name);
        payload.append("account_financing_no", this.form.data.account_financing_no);
        payload.append("is_pay", this.form.data.is_pay);
        payload.append("description", this.form.data.keterangan ? this.form.data.keterangan : "-");
        payload.append("amount", cleanAmount);
        payload.append("photo", this.form.data.selfi);
        payload.append("latitude", this.form.data.latitude ? this.form.data.latitude : 0);
        payload.append("longitude", this.form.data.latitude ? this.form.data.latitude : 0);
        payload.append("account_cash_code", this.user.account_cash_code);
        payload.append("created_by", this.user.fa_code);
        if (this.isOnline) {
          try {
            const req = await services.transactionPenagihan(payload, this.user.token);
            const response = req.data;
            if (response.status === true) {
              this.$store.dispatch("showAlert", {
                show: true,
                msg: "Berhasil disimpan!",
                color: "success",
                position: "top",
              });

              setTimeout(() => {
                window.location.reload();
              }, 3000);
            } else {
              throw new Error(response.msg || "Gagal menyimpan data.");
            }
          } catch (error) {
            this.$store.dispatch("showAlert", {
              show: true,
              msg: error.message || "Terjadi kesalahan saat menyimpan.",
              color: "error",
              position: "top",
            });
          }
        } else {
          try {
            await addDataWithoutEncryption("form_penagihan", payload);

            this.$store.dispatch("showAlert", {
              show: true,
              msg: "Berhasil disimpan, data akan dikirim saat online.",
              color: "warning",
              position: "top",
            });

            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } catch (error) {
            this.$store.dispatch("showAlert", {
              show: true,
              msg: error.message || "Gagal menyimpan offline.",
              color: "error",
              position: "top",
            });
          }
        }
      } finally {
        this.loadingBtn = false;
      }
    },

    convertFormDataToObject(formData) {
      const object = {};
      formData.forEach((value, key) => {
        object[key] = value;
      });
      return object;
    },
    formatRupiah(value) {
      return new Intl.NumberFormat("id-ID").format(value);
    },

    clearAll() {
      this.form.data.account_financing_no = null;
      this.form.data.selfi = null;
      this.form.data.nominal_seharusnya = 0;
      this.form.data.nominal = 0;
      this.form.data.keterangan = "";
      this.form.data.is_pay = false;
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.sheet = false;
      this.isSwitched = false;
      this.selectedAnggota = null;
      this.anggotaDetail = null;
      this.stopCameraStream();
    },
  },
  async created() {
    await this.geoLocation();
  },
  computed: {
    ...mapGetters(["user", "userRole"]),
    isFormIncomplete() {
      const formData = this.form.data;

      for (const key in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, key)) {
          const value = formData[key];

          // Lewati pengecekan jika key adalah latitude atau longitude
          if (key === "latitude" || key === "longitude") {
            continue;
          }

          // Cek jika value null atau object dengan salah satu nilai null
          if (value === null || (typeof value === "object" && Object.values(value).includes(null))) {
            return true;
          }
        }
      }
      return false;
    },
  },
  watch: {
    isSwitched(newValue) {
      // Jika di-switch ON, set nilai bayar ke 1, jika OFF kosongkan
      this.form.data.is_pay = newValue ? 1 : 0;

      if (this.form.data.is_pay === 1) {
        this.form.data.keterangan = "-";
      } else if (this.form.data.is_pay === 0) {
        this.form.data.nominal = 0;
      }
    },

    loader() {
      const l = this.loader;
      this[l] = !this[l];

      // setTimeout(() => (this[l] = false), 1000);

      this.loader = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-sheet {
  max-width: 480px;
  margin: auto;
  text-align: center;
  border-radius: 30px 30px 0 0;
  padding: 16px;
  position: relative;
}

.sheet-content {
  max-height: 70vh;
  overflow-y: auto;
  padding: 0 20px 20px 20px;
  -ms-overflow-style: none; /* Untuk Internet Explorer dan Edge */
  scrollbar-width: none; /* Untuk Firefox */
}

video.back-camera {
  width: 100%;
  height: auto;
  object-fit: cover; /* Menutupi area canvas dengan mempertahankan aspek rasio */
  object-position: center; /* Mengatur posisi gambar di tengah */
  transform: none;
}
canvas.back-camera {
  width: 100%;
  height: auto;
  object-fit: cover; /* Menutupi area canvas dengan mempertahankan aspek rasio */
  object-position: center; /* Mengatur posisi gambar di tengah */
  transform: none;
}
video.front-camera {
  width: 100%;
  height: auto;
  object-fit: cover; /* Menutupi area canvas dengan mempertahankan aspek rasio */
  object-position: center; /* Mengatur posisi gambar di tengah */
  transform: scaleX(-1); /* Memutar gambar secara horizontal */
}
canvas.front-camera {
  width: 100%;
  height: auto;
  object-fit: cover; /* Menutupi area canvas dengan mempertahankan aspek rasio */
  object-position: center; /* Mengatur posisi gambar di tengah */
  transform: scaleX(-1); /* Memutar gambar secara horizontal */
}
.selfi {
  width: 100%;
  height: auto;
  object-fit: cover; /* Menutupi area canvas dengan mempertahankan aspek rasio */
  object-position: center; /* Mengatur posisi gambar di tengah */
  transform: scaleX(-1); /* Memutar gambar secara horizontal */
}
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(119, 3, 123);
  border-radius: 4px;
  width: 100%;

  .camera-button {
    margin: 2rem;
    font-size: 12px;
    button {
      padding: 5px 20px 5px 20px;
      border-radius: 10px;
      background-color: rgb(119, 3, 123);
      border: none;
      color: white;
      .icon {
        font-size: 14px;
      }
    }
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 100%;
      height: 300px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;
    width: 100%;
    button {
      display: flex;
      padding: 10px;
      background-color: rgb(119, 3, 123);
      border: none;
      color: white;
      border-radius: 100%;
      .icon {
        font-size: 30px;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -3rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background-color: rgb(119, 3, 123);
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }

  //Custom loader
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
